import React from "react";
import "./styles.css";

const PartnersPurchaseGoodsProduct = ({ title, notation, icon, alt }) => {
  return (
    <div className="PartnersPurchaseGoodsProduct">
      <div className="PartnersPurchaseGoodsProduct__icon">
        <img src={icon.publicURL} alt={alt} />
      </div>
      <div className="[ PartnersPurchaseGoodsProduct__title ] [ Font_headerMobile Color_black ]">{title}</div>
      <div className="[ PartnersPurchaseGoodsProduct__notation ] [ Font_regularText Color_lightGrey ]">{notation}</div>
    </div>
  );
};

export { PartnersPurchaseGoodsProduct };

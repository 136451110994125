import cn from "classnames";
import { Link } from "gatsby";
import React from "react";
import { Button } from "../../Button";
import { HexagonYellow } from "../../ContactUs/ContactUsContainer/icons/HexagonYellow";
import "./styles.css";

const PartnersHeroContent = ({ className, title, whyUs, advantages, buttonPartners, buttonPurchases }) => {
  return (
    <div className={cn(className, "PartnersHeroContent")}>
      <h1 className="[ PartnersHeroContent__title ] [ Font_bigHeaderSerif sm:Font_headerSerif Color_black ]">
        {title}
      </h1>
      <div className="[ PartnersHeroContent__whyUsContainer ] [ Font_regularText ]">
        <HexagonYellow className="PartnersHeroContent__hexagon" />
        <p className="PartnersHeroContent__whyUs  Color_darkGrey">{whyUs}</p>

        <ul className="[ PartnersHeroContent__whyUsList ] [ Color_black ]">
          {advantages.map(advantage => (
            <li key={advantage} className="PartnersHeroContent__whyUsListItem">
              <span>&#8226;</span>
              <span>{advantage}</span>
            </li>
          ))}
        </ul>
      </div>

      <div className="PartnersHeroContent__buttons">
        <Link to="/partners/#new-partners">
          <Button black icon>
            {buttonPartners}
          </Button>
        </Link>
        <Link to="/partners/#purchases">
          <Button outlined_black icon>
            {buttonPurchases}
          </Button>
        </Link>
      </div>
    </div>
  );
};

export { PartnersHeroContent };

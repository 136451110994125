import { graphql } from "gatsby";
import React from "react";
import { ContactUs } from "../ContactUs";
import "./styles.css";

const PartnersCooperation = ({ title, slogan, stages, formHeader, formBody }) => {
  return (
    <div id="new-partners">
      <ContactUs
        className="PartnersCooperation"
        title={title}
        slogan={slogan}
        stages={stages}
        formHeader={formHeader}
        formBody={formBody}
        sloganIconColor="violet"
      />
    </div>
  );
};

PartnersCooperation.getLivePreviewData = ({ data, getAsset }) => ({
  partnersCooperation: {
    ...data.partnersCooperation,
  },
});

const partnersCooperationQuery = graphql`
  fragment PartnersCooperationFragment on MarkdownRemarkFrontmatter {
    partnersCooperation {
      title
      slogan
      stages
      formHeader {
        title
        text
      }
    }
  }
`;

export { PartnersCooperation, partnersCooperationQuery };

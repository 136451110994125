import { graphql } from "gatsby";
import React from "react";
import "./styles.css";

const PartnersWorkPolicy = ({ title, notation, pricingPolicy, factors }) => {
  return (
    <div className="PartnersWorkPolicy">
      <div className="[ PartnersWorkPolicy__container ] [ Content ]">
        <div className="PartnersWorkPolicy__leftBlock">
          <h2 className="Font_headerSerif">{title}</h2>
          <div className="[ PartnersWorkPolicy__notation ] [ Font_smallText Color_lightGrey ]">{notation}</div>{" "}
        </div>
        <div className="PartnersWorkPolicy__rightBlock">
          <div className="Font_headerMobile">{pricingPolicy}</div>
          <ul>
            {factors.map(factor => (
              <li className=" [ PartnersWorkPolicy__factor Color_darkGrey ] [ Font_mediumText ]" key={factor}>
                {factor}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

PartnersWorkPolicy.getLivePreviewData = ({ data, getAsset }) => ({
  partnersWorkPolicy: {
    ...data.partnersWorkPolicy,
  },
});

const partnersWorkPolicyQuery = graphql`
  fragment PartnersWorkPolicyFragment on MarkdownRemarkFrontmatter {
    partnersWorkPolicy {
      title
      notation
      pricingPolicy
      factors
    }
  }
`;

export { PartnersWorkPolicy, partnersWorkPolicyQuery };

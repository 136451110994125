import cn from "classnames";
import React from "react";
import { Phone } from "../../../icons/footer";
import "./styles.css";

const PartnersPurchaseGoodsContent = ({ className, title, notation, phoneNumber, messengersWithLinks }) => {
  return (
    <div className={cn(className, "PartnersPurchaseGoodsContent")}>
      <h2 className="[ PartnersPurchaseGoodsContent__title ] [ Font_mediumHeaderSerif Color_black ]">{title}</h2>
      <p className="[ PartnersPurchaseGoodsContent__notation ] [ Font_regularText Color_darkGrey ]">{notation}</p>

      <p className="PartnersPurchaseGoodsContent__phoneNumber">
        <Phone className="PartnersPurchaseGoodsContent__icon" />
        <a
          className="[ PartnersPurchaseGoodsContent__phoneNumberLink ] [ Font_headerMobile Color_black ]"
          href={`tel:${phoneNumber}`}
        >
          {phoneNumber}
        </a>
      </p>

      <div className="PartnersPurchaseGoodsContent__messengers">
        {messengersWithLinks.map(({ name, href }) => (
          <a href={href} className="[ PartnersPurchaseGoodsContent__messenger ] [ Font_button Color_black ]" key={name}>
            {name}
          </a>
        ))}
      </div>
    </div>
  );
};

export { PartnersPurchaseGoodsContent };

import { graphql } from "gatsby";
import React from "react";
import { PartnersHeroContent } from "./PartnersHeroContent";
import { LogoHoney } from "../LogoHoney";
import { LinePartners } from "../LinePartners/LinePartners";
import "./styles.css";

const PartnersHero = ({ title, whyUs, advantages, buttonPartners, buttonPurchases, partners }) => {
  return (
    <section className="PartnersHero">
      <div className="[ PartnersHero__container ] [ Content ]">
        <PartnersHeroContent
          className="PartnersHero__content"
          title={title}
          whyUs={whyUs}
          advantages={advantages}
          buttonPartners={buttonPartners}
          buttonPurchases={buttonPurchases}
        />

        <LogoHoney className="PartnersHero__logo" />
      </div>

      <LinePartners className="PartnersHero__partners" partners={partners} />
    </section>
  );
};

PartnersHero.getLivePreviewData = ({ data, getAsset }) => ({
  partnersHero: {
    ...data.partnersHero,
  },
});

const partnersHeroQuery = graphql`
  fragment PartnersHeroFragment on MarkdownRemarkFrontmatter {
    partnersHero {
      title
      whyUs
      advantages
      buttonPartners
      buttonPurchases
    }
  }
`;

export { PartnersHero, partnersHeroQuery };

import { graphql } from "gatsby";
import React from "react";
import { List } from "./../List";
import { Requirements } from "./Requirements";
import "./styles.css";

const PartnersPurchases = ({ title, text, principles, requirementsHeader, requirements }) => {
  return (
    <div id="purchases" className="PartnersPurchases">
      <div className="Content">
        <div className="PartnersPurchases__content">
          <List
            className="PartnersPurchases__list"
            titleBig={title}
            stages={principles}
            slogan={text}
            sloganIconColor="yellow"
          />
          <Requirements
            className="PartnersPurchases__requirements"
            title={requirementsHeader}
            requirements={requirements}
          />
        </div>
      </div>
    </div>
  );
};

PartnersPurchases.getLivePreviewData = ({ data, getAsset }) => ({
  partnersPurchases: {
    ...data.partnersPurchases,
  },
});

const partnersPurchasesQuery = graphql`
  fragment PartnersPurchasesFragment on MarkdownRemarkFrontmatter {
    partnersPurchases {
      title
      text
      principles
      requirementsHeader
      requirements
    }
  }
`;

export { PartnersPurchases, partnersPurchasesQuery };

import React from "react";
import cn from "classnames";
import "./styles.css";
export const Requirements = ({ className, title, requirements }) => {
  return (
    <div className={cn(className, "[ Requirements ]")}>
      <div className="[ Requirements__title ] [ Font_smallHeaderSerif Color_black ]">{title}</div>
      <ul className=" [ Requirements__list ] [ Font_mediumText Color_black ] ">
        {requirements.map(requirement => (
          <li className="Requirement">
            <div className=" [ Requirement__dote ] [ Color_yellow ]">•</div>
            <div className="Requirement__text">{requirement}</div>
          </li>
        ))}
      </ul>
    </div>
  );
};

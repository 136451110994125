import { graphql } from "gatsby";
import React from "react";
import { PartnersPurchaseGoodsContent } from "./PartnersPurchaseGoodsContent";
import { PartnersPurchaseGoodsProduct } from "./PartnersPurchaseGoodsProduct";
import "./styles.css";

const PartnersPurchaseGoods = ({ title, notation, phoneNumber, messengersWithLinks, purchaseGoods }) => {
  return (
    <section className="PartnersPurchaseGoods">
      <div className="[ PartnersPurchaseGoods__container ] [ Content ]">
        <div className="PartnersPurchaseGoods__products">
          {purchaseGoods.map(product => (
            <PartnersPurchaseGoodsProduct key={product.title} {...product} />
          ))}
        </div>

        <PartnersPurchaseGoodsContent
          className="PartnersPurchaseGoods__content"
          title={title}
          notation={notation}
          phoneNumber={phoneNumber}
          messengersWithLinks={messengersWithLinks}
        />
      </div>
    </section>
  );
};

PartnersPurchaseGoods.getLivePreviewData = ({ data, getAsset }) => ({
  partnersPurchaseGoods: {
    ...data.partnersPurchaseGoods,
  },
});

const partnersPurchaseGoodsQuery = graphql`
  fragment PartnersPurchaseGoodsFragment on MarkdownRemarkFrontmatter {
    partnersPurchaseGoods {
      title
      notation
      phoneNumber
      messengersWithLinks {
        name
        href
      }
      purchaseGoods {
        title
        notation
        icon {
          publicURL
        }
        alt
      }
    }
  }
`;

export { PartnersPurchaseGoods, partnersPurchaseGoodsQuery };

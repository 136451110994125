import React from "react";
import Helmet from "react-helmet";
import { Layout } from "../components/Layout";
import { PartnersCooperation } from "../components/PartnersCooperation";
import { PartnersHero } from "../components/PartnersHero";
import { PartnersPurchaseGoods } from "../components/PartnersPurchaseGoods";
import { PartnersPurchases } from "../components/PartnersPurchases";
import { PartnersWorkPolicy } from "../components/PartnersWorkPolicy";

const PartnersPageTemplate = ({ layout, ...props }) => {
  return (
    <>
      <Helmet>
        <title>{props.seo.title}</title>
        <meta name="description" content={props.seo.description} />
        <meta name="keywords" content={props.seo.keywords} />
      </Helmet>

      <PartnersHero partners={layout.partners} {...props.partnersHero} />
      <PartnersCooperation formBody={layout.formBody} {...props.partnersCooperation} />
      <PartnersWorkPolicy {...props.partnersWorkPolicy} />
      <PartnersPurchases {...props.partnersPurchases} />
      <PartnersPurchaseGoods {...props.partnersPurchaseGoods} />
    </>
  );
};

PartnersPageTemplate.getLivePreviewData = props => ({
  ...Layout.getLivePreviewData(props),
  ...PartnersHero.getLivePreviewData(props),
  ...PartnersCooperation.getLivePreviewData(props),
  ...PartnersWorkPolicy.getLivePreviewData(props),
  ...PartnersPurchases.getLivePreviewData(props),
  ...PartnersPurchaseGoods.getLivePreviewData(props),
});

const PartnersPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout {...data.layout.frontmatter}>
      <PartnersPageTemplate layout={data.layout.frontmatter} {...frontmatter} />
    </Layout>
  );
};

const pageQuery = graphql`
  query PartnersPageTemplate {
    layout: markdownRemark(frontmatter: { meta: { eq: "layout" } }) {
      frontmatter {
        ...LayoutFragment
        formBody {
          policy
          buttonLabel
          successMessage
          successButtonLabel
          errorMessage
          errorButtonLabel
        }
        partners {
          childImageSharp {
            fluid(maxWidth: 192) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }

    markdownRemark(frontmatter: { templateKey: { eq: "partners" } }) {
      frontmatter {
        seo {
          title
          description
          keywords
        }
        ...PartnersHeroFragment
        ...PartnersCooperationFragment
        ...PartnersWorkPolicyFragment
        ...PartnersPurchasesFragment
        ...PartnersPurchaseGoodsFragment
      }
    }
  }
`;

export default PartnersPage;

export { pageQuery, PartnersPageTemplate };
